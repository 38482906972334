import {
  select,
  put,
  take,
  fork,
  takeEvery,
  putResolve
} from 'redux-saga/effects';
import moment from 'moment';

import AvailabilityModel from 'models/sitter/Availability';
import CheckAvailability from 'containers/sitter/CheckAvailability';
import ErrorModal from 'containers/ErrorModal';
import {loadSchedule} from 'actions/sitter/availability';
import {getParentPhoneNumber} from 'actions/sitter/bookings';
import BookingDetails from 'containers/sitter/BookingDetails';
import {
  closeModal,
  openModal
} from 'actions/modal';
import {loadProfile} from 'actions/profile';
import {
  loadBookingDetails,
  loadParentSitterNotes
} from 'actions/sitter/bookings';
import {getAuthToken} from 'selectors/session';
import {
  ADD_BOOKING_NOTE_SUCCESS,
  OPEN_BOOKING_DETAILS_MODAL,
  END_BOOKING_SUCCESS,
  END_BOOKING_FAILURE,
  START_BOOKING_SUCCESS,
  START_BOOKING_FAILURE,
  START_MEAL_BREAK_SUCCESS,
  START_MEAL_BREAK_FAILURE,
  END_MEAL_BREAK_SUCCESS,
  END_MEAL_BREAK_FAILURE,
  CONFIRM_BOOKING_SUCCESS,
  CONFIRM_BOOKING_FAILURE,
  PHONE_CALL,
  LOAD_BOOKING_DETAILS_SUCCESS
} from 'constants/sitter/bookings';
import {getBookingParentId} from 'selectors/bookings';
import {getAvailabilities} from 'selectors/sitter/availability';

export function * parentNoteAdd () {
  while (true) {
    yield take(ADD_BOOKING_NOTE_SUCCESS);
    yield fork(parentNoteAddEffect);
  }
}

export function * parentNoteAddEffect () {
  const authToken = yield select(getAuthToken);
  const parentId = yield select(getBookingParentId);
  yield put(loadParentSitterNotes(authToken, parentId));
}

export function * openBookingDetails () {
  while (true) {
    const {bookingId} = yield take(OPEN_BOOKING_DETAILS_MODAL);
    yield fork(openBookingDetailsEffect, bookingId);
  }
}

export function * openBookingDetailsEffect (bookingId) {
  const options = {
    isBookingModal: true,
    bookingId
  }
  yield put(openModal(BookingDetails, 'Booking Details', options, {}));
}

export function * getBookingDetails () {
  yield takeEvery(LOAD_BOOKING_DETAILS_SUCCESS, loadBookingDetailsEffect)
}

function * loadBookingDetailsEffect () {
  const parentId = yield select(getBookingParentId);
  const authToken = yield select(getAuthToken);
  yield put(loadParentSitterNotes(authToken, parentId))
}

export function * startBookingSuccess () {
  while (true) {
    const action = yield take(START_BOOKING_SUCCESS);
    const booking = action.response.entities.bookings[action.response.result];
    yield fork(startBookingSuccessEffect, booking.id);
  }
}

export function * startBookingSuccessEffect (bookingId) {
  const authToken = yield select(getAuthToken);
  yield put(loadProfile(authToken));
  yield put(loadBookingDetails(authToken, bookingId));
}

export function * startBookingFailure () {
  while (true) {
    const {error} = yield take(START_BOOKING_FAILURE);
    yield fork(startBookingFailureEffect, error);
  }
}

export function * startBookingFailureEffect (error) {
  yield put(loadSchedule());
  yield put(closeModal());
  yield put(openModal(ErrorModal, 'Something went wrong', {message: error.message}, {}));
}

export function * startMealBreakSuccess () {
  while (true) {
    const action = yield take(START_MEAL_BREAK_SUCCESS);
    const booking = action.response.entities.bookings[action.response.result];
    yield fork(startMealBreakSuccessEffect, booking.id);
  }
}

export function * startMealBreakSuccessEffect (bookingId) {
  const authToken = yield select(getAuthToken);
  yield put(loadProfile(authToken));
  yield put(loadBookingDetails(authToken, bookingId));
}

export function * startMealBreakFailure () {
  while (true) {
    const {error} = yield take(START_MEAL_BREAK_FAILURE);
    yield fork(startMealBreakFailureEffect, error);
  }
}

export function * startMealBreakFailureEffect (error) {
  yield put(loadSchedule());
  yield put(closeModal());
  yield put(openModal(ErrorModal, 'Something went wrong', {message: error.message}, {}));
}

export function * endMealBreakSuccess () {
  while (true) {
    const action = yield take(END_MEAL_BREAK_SUCCESS);
    const booking = action.response.entities.bookings[action.response.result];
    yield fork(endMealBreakSuccessEffect, booking.id);
  }
}

export function * endMealBreakSuccessEffect (bookingId) {
  const authToken = yield select(getAuthToken);
  yield put(loadProfile(authToken));
  yield put(loadBookingDetails(authToken, bookingId));
}

export function * endMealBreakFailure () {
  while (true) {
    const {error} = yield take(END_MEAL_BREAK_FAILURE);
    yield fork(endMealBreakFailureEffect, error);
  }
}

export function * endMealBreakFailureEffect (error) {
  yield put(loadSchedule());
  yield put(closeModal());
  yield put(openModal(ErrorModal, 'Something went wrong', {message: error.message}, {}));
}

export function * endBookingSuccess () {
  while (true) {
    const action = yield take(END_BOOKING_SUCCESS);
    const booking = action.response.entities.bookings[action.response.result];
    yield fork(endBookingSuccessEffect, booking.id);
  }
}

export function * endBookingSuccessEffect (bookingId) {
  const authToken = yield select(getAuthToken);
  yield put(loadProfile(authToken));
  yield put(loadBookingDetails(authToken, bookingId));
}

export function * endBookingFailure () {
  while (true) {
    const {error} = yield take(END_BOOKING_FAILURE);
    yield fork(endBookingFailureEffect, error);
  }
}

export function * endBookingFailureEffect (error) {
  yield put(loadSchedule());
  yield put(closeModal());
  yield put(openModal(ErrorModal, 'Something went wrong', {message: error.message}, {}));
}

export function * confirmBookingSuccess () {
  while (true) {
    const action = yield take(CONFIRM_BOOKING_SUCCESS);
    const booking = action.response.entities.bookings[action.response.result];
    yield putResolve(loadSchedule());
    yield fork(confirmBookingSuccessEffect, booking);
  }
}

export function * confirmBookingSuccessEffect (booking) {
  const bookingId = booking.id;
  const authToken = yield select(getAuthToken);
  const availabilities = yield select(getAvailabilities);
  const endTime = moment(booking.end_time);
  const futureAvailabilities = availabilities.filter(a => moment.duration(moment(a.start_time).diff(endTime)).asHours() > 0);
  const allFarOut = futureAvailabilities.every(a => moment.duration(moment(a.start_time).diff(endTime)).asHours() > 6);
  yield put(closeModal());
  if (futureAvailabilities.length && !allFarOut) {
    const availability = new AvailabilityModel(futureAvailabilities[0]);
    yield put(openModal(CheckAvailability, 'Thank you for confirming', {
      booking: availability,
      duration: `${moment.duration(availability.start.diff(endTime)).asHours()} hours`,
      availability
    }, {}));
  }

  yield put(loadBookingDetails(authToken, bookingId));
}

export function * confirmBookingFailure () {
  while (true) {
    const {error} = yield take(CONFIRM_BOOKING_FAILURE);
    yield fork(confirmBookingFailureEffect, error);
  }
}

export function * confirmBookingFailureEffect (error) {
  yield put(loadSchedule());
  yield put(closeModal());
  yield put(openModal(ErrorModal, 'Something went wrong', {message: error.message}, {}));
}

export function * phoneNumber () {
  yield takeEvery(PHONE_CALL, phoneNumberEffect);
}

export function * phoneNumberEffect (action) {
  const {
    authToken,
    parentId
  } = action;

  let result = yield putResolve(getParentPhoneNumber(authToken, parentId));
  const number = result.response.entities.bridge_number[result.response.result].bridge_number;

  window.location.href = `tel:${number}`;
}
