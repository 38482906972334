import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import FormField from 'forms/controls/FormField';

export default class BookingActionTimeInput extends React.Component {
  static propTypes = {
    date: PropTypes.object
  }

  render () {
    return (
      <div>
        <div className="o-layout">
          <div className="o-layout__item u-1/1 u-center-text">
            {!!this.props.date && this.props.date.format('MMMM D')}
          </div>
        </div>
        <div className="o-layout">
          <div className="o-layout__item u-1/1">
            <FormField
                fields={[
                  {
                    parse: value => moment(value, 'YYYY-MM-DD'),
                    format: value => value == null ? '' : value.format('YYYY-MM-DD'),
                    name: 'date',
                    placeholder: 'Date',
                    type: 'date'
                  },
                  {
                    parse: value => moment(value, 'HH:mm:ss'),
                    format: value => value == null ? '' : value.format('HH:mm:ss'),
                    name: 'time',
                    placeholder: 'Time',
                    type: 'time'
                  }
                ]}
            />
          </div>
        </div>
      </div>
    );
  }
}
