import {
  all
} from 'redux-saga/effects';
import {hudComplete} from 'sagas/hud'
import {
  loginSuccess
} from 'sagas/sitter/login'
import {
  logOut
} from 'sagas/sitter/logOut'
import {
  removeAuthToken,
  setAuthToken,
  passwordChangeSuccess,
  passwordChangeFailure,
  passwordResetSuccess,
  passwordResetFailure
} from 'sagas/session'
import {
  allFail
} from 'sagas/sitter/errors'
import {
  hydrate,
  hydrationComplete
} from 'sagas/hydrate'
import {
  openMapPage
} from 'sagas/sitter/navigation'
import {
  loadSchedule,
  availabilityAddSuccess,
  unavailabilityAddSuccess,
  availabilityAddFailure,
  unavailabilityAddFailure,
  availabilityDeleteSuccess,
  availabilityDeleteFailure
} from 'sagas/sitter/availability'
import {
  parentNoteAdd,
  openBookingDetails,
  getBookingDetails,
  endBookingSuccess,
  endBookingFailure,
  startBookingSuccess,
  startBookingFailure,
  startMealBreakSuccess,
  startMealBreakFailure,
  endMealBreakSuccess,
  endMealBreakFailure,
  confirmBookingSuccess,
  confirmBookingFailure,
  phoneNumber
} from 'sagas/sitter/booking'
import {
  refreshAllSearches,
  refreshOneTimeSearches,
  refreshRecurringSearches,
  refreshInterestedSearches,
  showIntroModal,
  searchToBook,
  searchToBookFailure
} from 'sagas/sitter/search'
import {
  moveToRegisterStep,
  moveToWaitList,
  signUp,
  moveToWaitListSuccess,
  submitProfileSuccess
} from 'sagas/parent/signUp'
import {
  auth as facebookAuth,
  login as facebookLogin,
  signUpSuccess as facebookSignUpSuccess
} from 'sagas/facebook'
import {
  healthCheck,
} from 'sagas/healthCheck'

export default function *rootSaga () {
  yield all([
    ...[
      loginSuccess()
    ],
    logOut(),
    hudComplete(),
    hydrate(),
    hydrationComplete(),
    openMapPage(),
    ...[
      removeAuthToken(),
      setAuthToken(),
      passwordChangeSuccess(),
      passwordChangeFailure(),
      passwordResetSuccess(),
      passwordResetFailure()
    ],
    ...[
      showIntroModal(),
      refreshAllSearches(),
      refreshOneTimeSearches(),
      refreshRecurringSearches(),
      refreshInterestedSearches(),
      searchToBook(),
      searchToBookFailure()
    ],
    ...[
      loadSchedule(),
      availabilityAddSuccess(),
      unavailabilityAddSuccess(),
      availabilityAddFailure(),
      unavailabilityAddFailure(),
      availabilityDeleteSuccess(),
      availabilityDeleteFailure()
    ],
    ...[
      parentNoteAdd(),
      openBookingDetails(),
      getBookingDetails(),
      startBookingSuccess(),
      startBookingFailure(),
      startMealBreakSuccess(),
      startMealBreakFailure(),
      endMealBreakSuccess(),
      endMealBreakFailure(),
      endBookingSuccess(),
      endBookingFailure(),
      confirmBookingSuccess(),
      confirmBookingFailure(),
      phoneNumber()
    ],
    ...[
      facebookAuth(),
      facebookLogin(),
      facebookSignUpSuccess(),
    ],
    ...[
      healthCheck()
    ],
    ...[
      allFail(),
    ],
    moveToRegisterStep(),
    moveToWaitList(),
    moveToWaitListSuccess(),
    signUp(),
    submitProfileSuccess()
  ])
}
