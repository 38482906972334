import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
  openMapPage as openMapPageAction,
  setTitle as setTitleAction
} from 'actions/navigation'
import {
  closeModal as closeModalAction,
} from 'actions/modal';
import BookingDetailsComponent from 'components/BookingDetails';
import {track} from 'utils/track';
import {
  getBookingModel,
  getSitterParentNotes,
  getEndTimeTime,
  getIsCareAtWork,
  getShouldDisplayContactInfo,
  getBookingParentId,
  getParamsBookingId
} from 'selectors/bookings';
import {
  getAuthToken
} from 'selectors/session';
import {
  getFeatures
} from 'selectors/settings';
import {
  getModalOptions
} from 'selectors/ui';
import {
  endBooking as endBookingAction,
  confirmBooking as confirmBookingAction,
  startBooking as startBookingAction,
  startMealBreak as startMealBreakAction,
  endMealBreak as endMealBreakAction,
  noteAdd as noteAddAction,
  phoneCall as phoneCallAction,
  loadParentSitterNotes as loadParentSitterNotesAction,
  loadBookingDetails as loadBookingDetailsAction
} from 'actions/sitter/bookings';
import {
  healthCheck as healthCheckAction,
} from 'actions/healthCheck'
import {
  endBooking as endBookingSubmission,
  startBooking as startBookingSubmission,
  startMealBreak as startMealBreakSubmission,
  endMealBreak as endMealBreakSubmission,
  addNote as addNoteSubmission,
  phoneCall as phoneCallSubmission
} from 'submissions/sitter/booking';

export class BookingDetails extends React.Component {
  static propTypes = {
    noteAdd: PropTypes.func.isRequired,
    openMapPage: PropTypes.func.isRequired,
    authToken: PropTypes.string.isRequired,
    booking: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
    confirmBooking: PropTypes.func.isRequired,
    endBooking: PropTypes.func.isRequired,
    endTime: PropTypes.object,
    startBooking: PropTypes.func.isRequired,
    startTime: PropTypes.object,
    notes: PropTypes.array.isRequired,
    phoneCall: PropTypes.func.isRequired,
    healthCheck: PropTypes.func.isRequired,
    setTitle: PropTypes.func.isRequired,
    isCareAtWork: PropTypes.bool.isRequired,
    shouldDisplayContactInfo: PropTypes.bool.isRequired,
    parentId: PropTypes.number,
    bookingId: PropTypes.string.isRequired
  }

  componentDidMount () {
    if (this.props.modalOptions.isBookingModal) {
      track('Booking Details Modal - View');
    } else {
      this.props.setTitle('Booking Details')
      track('Booking Details Page - View')
    }

    this.props.loadBookingDetails(this.props.authToken, this.props.bookingId)
  }

  onConfirmBooking () {
    return () => {
      if (this.props.features.health_check) {
        this.props.healthCheck(() => {
          this.props.confirmBooking(this.props.authToken, this.props.booking.id)
        })
      } else {
        this.props.confirmBooking(this.props.authToken, this.props.booking.id)
      }
    }
  }

  render () {
    if (!this.props.parentId) {
      return null
    }

    return (
      <div className="o-layout">
        <div className="o-layout__item u-1/1">
          <BookingDetailsComponent
              booking={this.props.booking}
              closeModal={this.props.closeModal}
              date={this.props.endTime}
              hideMessages={this.props.isCareAtWork}
              notes={this.props.notes}
              onCallPhone={phoneCallSubmission(this.props.authToken, this.props.parentId, this.props.phoneCall)}
              onConfirmBookingClick={this.onConfirmBooking()}
              onStartBookingClick={startBookingSubmission(this.props.authToken, this.props.booking.id, this.props.startBooking)}
              onStartMealBreakClick={startMealBreakSubmission(this.props.authToken, this.props.booking.id, this.props.startMealBreak)}
              onEndMealBreakClick={endMealBreakSubmission(this.props.authToken, this.props.booking.id, this.props.endMealBreak)}
              onEndBookingClick={endBookingSubmission(this.props.authToken, this.props.booking.id, this.props.endBooking)}
              onNoteAdd={addNoteSubmission(this.props.authToken, this.props.parentId, this.props.noteAdd)}
              openMapPage={this.props.openMapPage}
              showContactInfo={this.props.shouldDisplayContactInfo}
          />
        </div>
      </div>
    );
  }
}

export const makeMapStateToProps = () => {
  return (state, ownProps) => {
    return {
      endTime: getEndTimeTime(state, ownProps),
      authToken: getAuthToken(state, ownProps),
      booking: getBookingModel(state, ownProps),
      notes: getSitterParentNotes(state, ownProps),
      features: getFeatures(state, ownProps),
      modalOptions: getModalOptions(state, ownProps),
      isCareAtWork: getIsCareAtWork(state, ownProps),
      shouldDisplayContactInfo: getShouldDisplayContactInfo(state, ownProps),
      parentId: getBookingParentId(state, ownProps),
      bookingId: getParamsBookingId(state, ownProps)
    };
  };
};

const mapDispatchToProps = {
  noteAdd: noteAddAction,
  endBooking: endBookingAction,
  startBooking: startBookingAction,
  startMealBreak: startMealBreakAction,
  endMealBreak: endMealBreakAction,
  closeModal: closeModalAction,
  openMapPage: openMapPageAction,
  confirmBooking: confirmBookingAction,
  phoneCall: phoneCallAction,
  healthCheck: healthCheckAction,
  setTitle: setTitleAction,
  loadParentSitterNotes: loadParentSitterNotesAction,
  loadBookingDetails: loadBookingDetailsAction
}

export default connect(makeMapStateToProps, mapDispatchToProps)(BookingDetails);
