export const PAST_BOOKINGS_REQUEST = 'PAST_BOOKINGS_REQUEST';
export const PAST_BOOKINGS_SUCCESS = 'PAST_BOOKINGS_SUCCESS';
export const PAST_BOOKINGS_FAILURE = 'PAST_BOOKINGS_FAILURE';
export const PARENT_PHONE_REQUEST = 'PARENT_PHONE_REQUEST';
export const PARENT_PHONE_SUCCESS = 'PARENT_PHONE_SUCCESS';
export const PARENT_PHONE_FAILURE = 'PARENT_PHONE_FAILURE';
export const FUTURE_BOOKINGS_REQUEST = 'FUTURE_BOOKINGS_REQUEST';
export const FUTURE_BOOKINGS_SUCCESS = 'FUTURE_BOOKINGS_SUCCESS';
export const FUTURE_BOOKINGS_FAILURE = 'FUTURE_BOOKINGS_FAILURE';
export const LOAD_BOOKING_DETAILS_REQUEST = 'LOAD_BOOKING_DETAILS_BOOKINGS_REQUEST';
export const LOAD_BOOKING_DETAILS_SUCCESS = 'LOAD_BOOKING_DETAILS_BOOKINGS_SUCCESS';
export const LOAD_BOOKING_DETAILS_FAILURE = 'LOAD_BOOKING_DETAILS_BOOKINGS_FAILURE';
export const OPEN_BOOKING_DETAILS_MODAL = 'OPEN_BOOKING_DETAILS_MODAL';
export const PHONE_CALL = 'PHONE_CALL';
export const CONFIRM_BOOKING_REQUEST = 'CONFIRM_BOOKING_REQUEST';
export const CONFIRM_BOOKING_SUCCESS = 'CONFIRM_BOOKING_SUCCESS';
export const CONFIRM_BOOKING_FAILURE = 'CONFIRM_BOOKING_FAILURE';
export const START_BOOKING_REQUEST = 'START_BOOKING_REQUEST';
export const START_BOOKING_SUCCESS = 'START_BOOKING_SUCCESS';
export const START_BOOKING_FAILURE = 'START_BOOKING_FAILURE';
export const START_MEAL_BREAK_REQUEST = 'START_MEAL_BREAK_REQUEST';
export const START_MEAL_BREAK_SUCCESS = 'START_MEAL_BREAK_SUCCESS';
export const START_MEAL_BREAK_FAILURE = 'START_MEAL_BREAK_FAILURE';
export const END_MEAL_BREAK_REQUEST = 'END_MEAL_BREAK_REQUEST';
export const END_MEAL_BREAK_SUCCESS = 'END_MEAL_BREAK_SUCCESS';
export const END_MEAL_BREAK_FAILURE = 'END_MEAL_BREAK_FAILURE';
export const END_BOOKING_REQUEST = 'END_BOOKING_REQUEST';
export const END_BOOKING_SUCCESS = 'END_BOOKING_SUCCESS';
export const END_BOOKING_FAILURE = 'END_BOOKING_FAILURE';
export const LOAD_PARENT_SITTER_NOTES_REQUEST = 'LOAD_PARENT_SITTER_NOTES_REQUEST';
export const LOAD_PARENT_SITTER_NOTES_SUCCESS = 'LOAD_PARENT_SITTER_NOTES_SUCCESS';
export const LOAD_PARENT_SITTER_NOTES_FAILURE = 'LOAD_PARENT_SITTER_NOTES_FAILURE';
export const ADD_BOOKING_NOTE_REQUEST = 'ADD_BOOKING_NOTE_REQUEST';
export const ADD_BOOKING_NOTE_SUCCESS = 'ADD_BOOKING_NOTE_SUCCESS';
export const ADD_BOOKING_NOTE_FAILURE = 'ADD_BOOKING_NOTE_FAILURE';
