import React from 'react';
import PropTypes from 'prop-types';
export default class BookingTimeView extends React.Component {
  static propTypes = {
    booking: PropTypes.object.isRequired,
    format: PropTypes.string
  };

  render () {
    const format = this.props.format || 'ddd, MMM D, YYYY [at] h:mma';
    return (
      <div className="c-booking-time">
        <BookingTime
            title="Booking start time"
            value={this.props.booking.start.format(format)}
        />
        {this.props.booking.mealStart != null && (
          <BookingTime
            title="Meal start time"
            value={this.props.booking.mealStart.format(format)}
          />
        )}
        {this.props.booking.mealEnd != null && (
          <BookingTime
            title="Meal end time"
            value={this.props.booking.mealEnd.format(format)}
          />
        )}
        <BookingTime
            title="Booking end time"
            value={this.props.booking.end.format(format)}
        />
      </div>
    );
  }
}

export class BookingTime extends React.Component { // eslint-disable-line react/no-multi-comp
  static propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  };

  render () {
    return (
      <div className="c-booking-time__time">
        <div className="c-booking-time__title">{this.props.title}</div>
        <div className="c-booking-time__value">{this.props.value}</div>
      </div>
    );
  }
}
