/* global confirm */

import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

import MapView from 'components/MapView'
import ProfileLineView from 'components/sitter/ProfileLineView'
import BookingTimeView from 'components/sitter/BookingTimeView'
import BookingNoteView from 'components/sitter/BookingNoteView'
import BookingChildrenView from 'components/sitter/BookingChildrenView'
import BigGreenButton from 'forms/fields/BigGreenButton'
import BigRedButton from 'forms/fields/BigRedButton'
import TabSwitchButton from 'forms/fields/TabSwitchButton'
import AvatarNameView from 'components/sitter/AvatarNameView'
import ProfileChildrenView from 'components/sitter/ProfileChildrenView'
import EmergencyContactsView from 'components/sitter/EmergencyContactsView'
import BookingPolicyView from 'components/sitter/BookingPolicyView'
import TrustedNotesView from 'components/sitter/TrustedNotesView'
import BookingActionTimeForm from 'forms/fields/BookingActionTime'
import SitterNotesView from 'components/sitter/SitterNotesView'

export default class BookingDetails extends React.Component {
  static propTypes = {
    onNoteAdd: PropTypes.func.isRequired,
    notes: PropTypes.array.isRequired,
    openMapPage: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    date: PropTypes.object,
    booking: PropTypes.object.isRequired,
    showContactInfo: PropTypes.bool.isRequired,
    onEndBookingClick: PropTypes.func.isRequired,
    onConfirmBookingClick: PropTypes.func.isRequired,
    onStartBookingClick: PropTypes.func.isRequired,
    onStartMealBreakClick: PropTypes.func.isRequired,
    onEndMealBreakClick: PropTypes.func.isRequired,
    onCallPhone: PropTypes.func.isRequired,
    hideMessages: PropTypes.bool.isRequired
  }

  state = {
    activeTab: 'bookingDetails',
    desiredNextState: 'none'
  }

  confirmBooking = () => {
    this.props.onConfirmBookingClick()
  }

  startBooking = () => {
    this.setState({desiredNextState: 'in_progress'})
    this.setState({activeTab: 'timePicker'})
  }

  endBooking = () => {
    this.setState({desiredNextState: 'ended'})
    this.setState({activeTab: 'timePicker'})
  }

  startMealBreak = () => {
    this.setState({desiredNextState: 'meal_break'})
    this.setState({activeTab: 'timePicker'})
  }

  endMealBreak = () => {
    this.setState({desiredNextState: 'in_progress'})
    this.setState({activeTab: 'timePicker'})
  }

  onTimePickerCancelled = () => {
    this.setState({desiredNextState: 'none'})
    this.setState({activeTab: 'bookingDetails'})
  }

  onTimePickerSubmit = values => {
    const formattedDate = values.bookingActionTime.date.format('MMM D, YYYY')
    const formattedTime = values.bookingActionTime.time.format('h:mma')
    const formattedDateAndTime = `${formattedDate} at ${formattedTime}`
    let prompt = ''
    switch (this.state.desiredNextState) {
      case 'in_progress':
        if (this.props.booking.mealStart == null) {
          prompt = `Please confirm this booking started on ${formattedDateAndTime}`
        } else {
          prompt = `Please confirm the meal break ended on ${formattedDateAndTime}`
        }
        break
      case 'meal_break':
        prompt = `Please confirm the meal break started on ${formattedDateAndTime}`
        break
      case 'ended':
        prompt = `Please confirm this booking ended on ${formattedDateAndTime}`
        break
    }
    if (confirm(prompt)) { // eslint-disable-line no-alert
      this.onTimePickerConfirmClick(values)
      this.setState({desiredNextState: 'none'})
      this.setState({activeTab: 'bookingDetails'})
    }
  }

  onTimePickerConfirmClick = values => {
    switch (this.state.desiredNextState) {
      case 'in_progress':
        if (this.props.booking.mealStart == null) {
          this.props.onStartBookingClick(values)
        } else {
          this.props.onEndMealBreakClick(values)
        }
        break
      case 'meal_break':
        this.props.onStartMealBreakClick(values)
        break
      case 'ended':
        this.props.onEndBookingClick(values)
        break
    }
  }

  renderTimePicker () {
    let title = ''
    let selectedDateTime = moment().startOf('minute')
    switch (this.state.desiredNextState) {
      case 'in_progress':
        if (this.props.booking.mealStart == null) {
          title = 'Please select the date and time when the booking started.'
          selectedDateTime = this.props.booking.start
        } else {
          title = 'Please select the date and time when the meal break ended.'
        }
        break
      case 'meal_break':
        title = 'Please select the date and time when the meal break started. Do not enter time for a meal break unless you are taking a duty free meal break.'
        break
      case 'ended':
        title = 'Please select the date and time when the booking ended.'
        break
    }
    return (
      <div className="o-layout">
        <div className="o-layout__item u-1/1">
          <p className="u-center-text">{title}</p>
        </div>
        <div className="o-layout__item u-1/1">
          <BookingActionTimeForm
              booking={this.props.booking}
              date={this.props.date}
              initialValues={{
                bookingActionTime: {
                  date: selectedDateTime,
                  time: selectedDateTime
                }
              }}
              onCancel={this.onTimePickerCancelled}
              onSubmit={this.onTimePickerSubmit}
          />
        </div>
      </div>
    )
  }

  renderParentDetails () {
    return (
      <div className="o-layout">
        <div className="o-layout__item u-1/1 u-3/5@desktop u-push-1/5@desktop">
          <AvatarNameView profile={this.props.booking.parent} />
          <ProfileChildrenView children={this.props.booking.parent.children} />
          {this.props.showContactInfo && (
            <EmergencyContactsView profile={this.props.booking.parent} />
          )}
          {(this.props.booking.parent.note || (!!this.props.notes && !!this.props.notes.length)) && (
            <div className="c-profile-parent-detail-notes">
              {this.props.booking.parent.note && (
                <TrustedNotesView note={this.props.booking.parent.note} />
              )}
              {(!!this.props.notes && !!this.props.notes.length) && (
                <SitterNotesView
                    notes={this.props.notes}
                    onSubmit={this.props.onNoteAdd}
                />
              )}
            </div>
          )}
        </div>
      </div>
    )
  }

  renderBookingDetails () {
    return (
      <div className="o-layout">
        <div className="o-layout__item u-1/1">
          <MapView
              location={this.props.booking.location}
              openMapPage={this.props.openMapPage}
          />
          <ProfileLineView
              closeModal={this.props.closeModal}
              hideMessages={this.props.hideMessages}
              onPhone={this.props.onCallPhone}
              profile={this.props.booking.parent}
              showPhoneNumber={this.props.showContactInfo}
          />
          <BookingTimeView booking={this.props.booking} />
          {this.props.booking.note && (
            <BookingNoteView booking={this.props.booking} />
          )}
          <BookingChildrenView children={this.props.booking.children} />
          {this.props.showContactInfo && (
            <EmergencyContactsView profile={this.props.booking.parent} />
          )}
          {this.props.booking.policy && (
            <BookingPolicyView policy={this.props.booking.policy} />
          )}
          <div className="o-layout u-margin-top">
            <div className="o-layout__item u-1/1 u-1/3@desktop u-push-1/3@desktop">
              {this.props.booking.status === 'created' && (
                <BigGreenButton label="Confirm Booking" onClick={this.confirmBooking} />
              )}
              {(this.props.booking.status === 'confirmed' && moment().isAfter(moment(this.props.booking.start).startOf('day'))) && (
                <BigGreenButton label="Start" onClick={this.startBooking} />
              )}
              {(this.props.booking.status === 'in_progress' && this.props.booking.mealStart == null) && (
                <BigGreenButton label="Start Meal Break" onClick={this.startMealBreak} />
              )}
              {this.props.booking.status === 'in_progress' && (
                <BigRedButton label="End Booking" onClick={this.endBooking} />
              )}
              {this.props.booking.status === 'meal_break' && (
                <BigRedButton label="End Meal Break" onClick={this.endMealBreak} />
              )}
              {this.props.booking.status === 'ended' && (
                <div className="c-booking-action c-booking-action--ended">{'Ended'}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  render () {
    return (
      <div>
        <div className="o-layout">
          {(this.state.activeTab == 'bookingDetails' || this.state.activeTab == 'parentDetails') && (
            <div className="o-layout__item u-1/1 u-3/5@desktop u-push-1/5@desktop">
              <div className="o-layout">
                <div className="o-layout__item u-1/2">
                  <TabSwitchButton
                      active={this.state.activeTab === 'bookingDetails'}
                      onClick={() => this.setState({activeTab: 'bookingDetails'})}
                  >{'Booking'}</TabSwitchButton>
                </div>
                <div className="o-layout__item u-1/2">
                  <TabSwitchButton
                      active={this.state.activeTab === 'parentDetails'}
                      onClick={() => this.setState({activeTab: 'parentDetails'})}
                  >{'Parent'}</TabSwitchButton>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="o-layout">
          <div className="o-layout__item u-1/1">
            {(this.state.activeTab === 'bookingDetails') && this.renderBookingDetails()}
            {(this.state.activeTab === 'parentDetails') && this.renderParentDetails()}
            {(this.state.activeTab === 'timePicker') && this.renderTimePicker()}
          </div>
        </div>
      </div>
    )
  }
}
