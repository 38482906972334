export function endBooking (authToken, bookingId, callback) {
  return values => {
    callback(authToken, bookingId, combineDateAndTime(values).format());
  };
}

export function confirmBooking (authToken, bookingId, callback) {
  return () => {
    callback(authToken, bookingId);
  };
}

export function startBooking (authToken, bookingId, callback) {
  return values => {
    callback(authToken, bookingId, combineDateAndTime(values).format());
  };
}

export function startMealBreak (authToken, bookingId, callback) {
  return values => {
    callback(authToken, bookingId, combineDateAndTime(values).format());
  };
}

export function endMealBreak (authToken, bookingId, callback) {
  return values => {
    callback(authToken, bookingId, combineDateAndTime(values).format());
  };
}

export function addNote (authToken, parentId, callback) {
  return ({parentNote}) => {
    callback(authToken, parentId, parentNote.note);
  };
}

export function phoneCall (authToken, parentId, callback) {
  return () => {
    callback(authToken, parentId);
  };
}

function combineDateAndTime (values) {
  const dateMoment = values.bookingActionTime.date;
  const timeMoment = values.bookingActionTime.time;
  return combineDateAndTimeMoments(dateMoment, timeMoment)
}

export function combineDateAndTimeMoments (dateMoment, timeMoment) {
  // Clone the first Moment object to avoid mutating it
  const combinedMoment = dateMoment.clone();

  // Set the hours, minutes, seconds, and milliseconds from the second Moment object
  combinedMoment
    .hour(timeMoment.hour())
    .minute(timeMoment.minute())
    .second(timeMoment.second())
    .millisecond(timeMoment.millisecond());

  return combinedMoment;
}
