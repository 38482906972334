import React from 'react';
import PropTypes from 'prop-types';

import FormButton from 'forms/controls/Button';

export default class BigGreenButton extends React.Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
  }

  render () {
    return (
      <FormButton
          className="c-form-button--primary c-form-button--block c-booking-action--confirm"
          label={this.props.label}
          onClick={this.props.onClick}
          type="button"
      />
    );
  }
}
