import moment from 'moment';
import { combineDateAndTimeMoments } from '../../submissions/sitter/booking';

export const timeRequiredAndNotInFuture = values => {
  const errors = {};
  if (!values || !values.date || !values.time) {
    errors.time = 'Required';
  } else if (combineDateAndTimeMoments(values.date, values.time).isAfter(moment())) {
    errors.time = 'Date and time cannot be in the future';
  }
  return errors;
};

export const parentNote = values => {
  const errors = {};
  if (!values || !values.note) {
    errors.note = 'Required';
  }
  return errors;
};
